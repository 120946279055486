// ContactUs.js
import React, { useState } from 'react';
import { Navbar } from './Navbar';
import emailjs from 'emailjs-com';
import './Contact.css';

export const ContactUs = () => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

  

    emailjs.send('service_irg5b6p', 'template_m7x3myc', templateParams, 'zCxzN6he9CrfCUQAr')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setSuccess('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      }, (err) => {
        console.error('Failed to send email. Error: ', err);
        setError('Failed to send message. Please try again later.');
      });
  };
  return (
    <div>
      <Navbar />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#FFFFFF',
          height: screenHeight,
          paddingTop: screenHeight * (150 / 1024),
        }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'auto',
        }}>
          <div
            style={{
              boxShadow: '0 4px 8px rgba(0,0,0,0.5)',
              backgroundColor: '#FFFFFF',
              padding: screenHeight * (20 / 1024),
              margin: '20px',
              width: (screenHeight <= screenWidth) ? screenWidth * (537 / 1440) : screenWidth,
              height: (screenHeight <= screenWidth) ? screenHeight * (580 / 1024) : screenHeight * (680 / 852),
            }}
          >
            {error && <div className='reg__error'>{error}</div>}
            {success && <div className='reg__success'>{success}</div>}
            <h1 style={{ fontSize: screenHeight * (22 / 1024), fontWeight: 'bold' }}>Contact us</h1>
            <div style={{ border: `${screenHeight * (0.5 / 1024)}px solid #ccc`, margin: (screenHeight <= screenWidth) ? `${screenHeight * (23 / 1024)}px ${screenWidth * (-18 / 1440)}px` : `${screenHeight * (23 / 1024)}px ${screenWidth * (-19 / 393)}px` }} />
            <form style={{ paddingTop: (screenHeight <= screenWidth) ? screenHeight * (15 / 1024) : screenHeight * (0 / 852) }} onSubmit={sendEmail} id='contact_form'>
              <label htmlFor="name">Your full name</label>
              <input
                type='text'
                placeholder='Enter your full name'
                value={name}
                required
                onChange={e => setName(e.target.value)}
                style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%' }}
              />

              <label htmlFor="email">Your email address</label>
              <input
                type='email'
                placeholder='Enter your email address'
                value={email}
                required
                onChange={e => setEmail(e.target.value)}
                style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%' }}
              />

              <label htmlFor="message">Your message</label>
              <textarea
                value={message}
                placeholder='Type here'
                required
                rows="5"
                onChange={e => setMessage(e.target.value)}
                style={{
                  textAlign: 'left',
                  paddingTop: 0,
                  borderRadius: 10,
                  backgroundColor: '#F1F1F1',
                  marginBottom: screenHeight * (70 / 1024),
                  height: screenHeight * (178 / 1024),
                  width: '100%',
                  padding: `${screenHeight * (10 / 1024)}px`,
                  resize: 'none'
                }}
              />
              <button
                className={(name && email && message) ? 'gradient1-color' : 'base-color'}
                style={{
                  borderRadius: 20,
                  cursor: 'pointer',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  color: (name && email && message) ? '#FFFFFF' : '#FFFFFF',
                }}
                type='submit'
              onSubmit={sendEmail}
              >
                Send message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

