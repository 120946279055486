import React, { useState, useEffect } from 'react';
import { Navbar } from './Navbar';
import { fdb } from '../config/firebaseSetup';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";

export const AdminPortal = () => {
    const [vendors, setVendors] = useState([]);

    useEffect(() => {
        const fetchVendors = async () => {
            try {
                // Query Firestore collection for vendors where vendorVerify is false
                const q = query(collection(fdb, "Vendors"), where("vendorVerify", "==", false));
                const querySnapshot = await getDocs(q);
                
                // Map through the querySnapshot and store vendors in state
                const vendorList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setVendors(vendorList);
            } catch (error) {
                console.error("Error fetching vendors: ", error);
            }
        };

        fetchVendors();
    }, []); // Empty dependency array ensures useEffect runs only once on component mount 

    const handleDecline = async(id) => {
        try {
            const vendorRef = doc(fdb,'Vendors',id); 
            await deleteDoc(vendorRef) 

            setVendors(prevVendors => prevVendors.filter(vendor => vendor.id !== id)); 
        }catch(error){
            console.error('Error declining vendor: ',error)
        }
    }

    const handleApprove = async (id) => {
        try {
            // Update the vendor's document in Firestore to set vendorVerify to true
            const vendorRef = doc(fdb, "Vendors", id);
            await updateDoc(vendorRef, {
                vendorVerify: true
            }); 

            const vendorSecondRef= doc(fdb,'Users',)
            
            // Remove the approved vendor from the local state
            setVendors(prevVendors => prevVendors.filter(vendor => vendor.id !== id)); 
        } catch (error) {
            console.error("Error approving vendor: ", error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className='main-container'>
                <div className='admin-container'>
                    <h2>Vendor Approval</h2>
                    <div className='vendor-list'>
                        {vendors.map(vendor => (
                            <div key={vendor.id} className='vendor-item'>
                                <p>Name: {vendor.vendorName}</p>
                                <p>Address: {vendor.vendorAddress}</p>
                                <p>Email: {vendor.vendorEmail}</p>
                                <p>Description: {vendor.vendorDescription}</p>
                                <button onClick={() => handleApprove(vendor.id)}>Approve</button> 
                                <button onClick={() => handleDecline(vendor.id)}>Decline</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};