import React, { useState, useEffect } from 'react';
import { Navbar } from './Navbar';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebaseSetup';
import './Login.css';

export const PasswordChange = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);



  /* The use effect ensure the timer is on and reloading the page does not make the timer go away */
  useEffect(() => {
    const disableUntil = localStorage.getItem('disableUntil');
    if (disableUntil) {
      const disableUntilTime = new Date(disableUntil).getTime();
      const currentTime = new Date().getTime();


      if (currentTime < disableUntilTime) {
        setIsDisabled(true);


        const remaining = Math.ceil((disableUntilTime - currentTime) / 1000);
        setRemainingTime(remaining);

        const timer = setInterval(() => {
          setRemainingTime(prev => {
            if (prev <= 1) {
              // ensures the text field can be ready to be used again 
              clearInterval(timer);
              setIsDisabled(false);
              // dependent on local storage
              localStorage.removeItem('disableUntil');
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    }
  }, []);

  const handlePasswordReset = e => {
    e.preventDefault();
    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setLoading(false);
        setIsModalOpen(true);
        setIsDisabled(true);
        setEmail("");
        const disableUntil = new Date(new Date().getTime() + 30000);
        localStorage.setItem('disableUntil', disableUntil);
        setRemainingTime(30);

        // Handles the timer 
        const timer = setInterval(() => {
          setRemainingTime(prev => {
            if (prev <= 1) {
              clearInterval(timer);
              setIsDisabled(false);
              localStorage.removeItem('disableUntil');
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Navbar />
      <div className='center'>
        <div className='auth'>
          {error && <div className='auth__error'>{error}</div>}
          <form onSubmit={handlePasswordReset} name='login_form'>
            <input
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
              disabled={isDisabled}
            />
            {isDisabled && <p>Try again in {remainingTime} seconds</p>}
            <button className="login-button gradient-color" type='submit' disabled={loading || isDisabled}>
              {loading ? 'Sending...' : 'Send Password Reset Email'}
            </button>
          </form>
        </div>
      </div>



      
      {isModalOpen && (
        <div className='modal-overlay'>
          <div className='modal'>
            <h2>Check Your Email</h2>
            <p>A email has been sent, Click on Login once finished</p>
            <button className="login-button gradient-color" onClick={() => setIsModalOpen(false)}>
              Close
            </button>
          </div>
        </div>
      )}


    </div>
  );
};
