import React from 'react'
import { Navbar } from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { fdb, storage } from '../config/firebaseSetup'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; 
import { collection, addDoc, GeoPoint, setDoc, doc } from "firebase/firestore";
import './VendorRegistration.css'
import { auth } from '../config/firebaseSetup'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import hiddenPassword from '../Assets/hiddenPassword.png'
import showPassword from '../Assets/showPassword.png'
import axios from 'axios'

export const VendorRegistration = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const [vendorName, setVendorName] = useState('')
    const [vendorAddress, setVendorAddress] = useState('')
    const [vendorpassword, setPassword] = useState('');
    const [vendorNumber, setVendorNumber] = useState('')
    const [vendorEmail, setVendorEmail] = useState('')
    const [vendorDescription, setVendorDescription] = useState('')
    const [vendorid, setVendorId] = useState('')
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [vendorImage, setVendorImage] = useState(null); 

    const [error, setError] = useState('')
    const navigate = useNavigate();

    const validatePassword = () => {
        //Check if both password and confirm password are the same(Make sure they are not empty as well)
        let isValid = true
        if (vendorpassword !== '' || confirmPassword !== '') {
            if (vendorpassword !== confirmPassword) {
                isValid = false
                setError('Passwords do not match')
            }
        }
        return isValid
    }

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!isConfirmPasswordVisible);
    }

    const getCoordinates = async (address) => {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    q: address,
                    format: 'json',
                    addressdetails: 1
                }
            });
            if (response.data.length > 0) {
                const { lat, lon } = response.data[0];
                return { lat, lon };
            } else {
                throw new Error('No coordinates found for the provided address');
            }
        } catch (error) {
            console.error('Error fetching coordinates:', error);
            throw error;
        }
    };

    const vendorRegister = async (e) => {



        e.preventDefault()

        if (!vendorImage) {
            setError('Vendor image is required');
            return;
        }

        console.log('Form submitted')

        const vendorData = {
            vendorName: vendorName,
            vendorAddress: vendorAddress,
            vendorNumber: vendorNumber,
            vendorEmail: vendorEmail,
            vendorDescription: vendorDescription,
            vendorVerify: false
        }

        try {
            // Add form data to Firestore "Vendor" collection 
            const coordinates = await getCoordinates(vendorAddress);
            vendorData.coordinates = new GeoPoint(parseFloat(coordinates.lat), parseFloat(coordinates.lon));

            if (validatePassword()) {
                console.log("We received the following information: ")
                console.log(vendorEmail)
                console.log(vendorpassword)
                const userCredential = await createUserWithEmailAndPassword(auth, vendorEmail, vendorpassword);
                const user = userCredential.user;
                await sendEmailVerification(user);
                vendorData.id = `Vendor_${user.uid}`
                const vendorRef = doc(fdb, "Vendors", user.uid);
                const imageRef = ref(storage, `vendorImages/${user.uid}`);
                await uploadBytes(imageRef, vendorImage);
                const img = await getDownloadURL(imageRef);
                vendorData.img = img;
                await setDoc(vendorRef, vendorData);
              
                // Clear form fields after successful submission
                setVendorName('');
                setVendorAddress('');
                setVendorNumber('');
                setVendorEmail('');
                setVendorDescription('');
                setPassword('');
                setConfirmPassword('');
                navigate('/verify-email');
            }

            console.log("Form data added to Firestore");
        } catch (error) {
            // Handle errors
            console.error("Error adding document: ", error);
            setError(error.message);
        }

    }

    const inputStylePassword = {
        width: '100%',  // Make the width responsive within its container
        padding: (screenHeight <= screenWidth) ? `${screenHeight * (12 / 1024)}px ${screenWidth * (10 / 1440)}px` : `${screenHeight * (12 / 1024)}px ${screenWidth * (10 / 393)}px`, // Add padding for better text visibility
        backgroundColor: '#F1F1F1',
        border: '1px solid #ccc', // Optional, adds definition
        borderRadius: '10px',
    };

    const form1 = () => {
        return (
            <div style={{
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                backgroundColor: '#FFFFFF',
                padding: screenHeight * (20 / 1024),
                margin: '20px',
                width: (screenHeight <= screenWidth) ? screenWidth * (837 / 1440) : screenWidth,
                height: (screenHeight <= screenWidth) ? screenHeight * (710 / 1024) : screenHeight * (680 / 852),
            }}>
                <header style={{ fontSize: screenHeight * (22 / 1024), fontWeight: 'bold' }}>Vendor Registration</header>
                <div style={{ border: `${screenHeight * (0.5 / 1024)}px solid #ccc`, margin: (screenHeight <= screenWidth) ? `${screenHeight * (23 / 1024)}px ${screenWidth * (-18 / 1440)}px` : `${screenHeight * (23 / 1024)}px ${screenWidth * (-19 / 393)}px` }} />
                {error && <div className='reg__error'>{error}</div>}
                <form style={{ paddingTop: (screenHeight <= screenWidth) ? screenHeight * (15 / 1024) : screenHeight * (0 / 852) }} onSubmit={vendorRegister} id='registration_form'>
                    <label for="orgName">Vendor Name</label>
                    <input
                        type='text'
                        placeholder='Enter your name'
                        value={vendorName}
                        required
                        onChange={e => setVendorName(e.target.value)}
                        style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%' }}
                    />

                    <label for="companyAddress">Vendor Address</label>
                    <input
                        type='text'
                        placeholder='Enter your address'
                        value={vendorAddress}
                        required
                        onChange={e => setVendorAddress(e.target.value)}
                        style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%' }}
                    />

                    <label for="phoneNumber">Phone Number</label>
                    <input
                        type='text'
                        placeholder='Enter your phone number'
                        value={vendorNumber}
                        required
                        onChange={e => setVendorNumber(e.target.value)}
                        style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%' }}
                    />



                    <label for="email">Email</label>
                    <input
                        type='email'
                        placeholder='Enter your email address'
                        value={vendorEmail}
                        required
                        onChange={e => setVendorEmail(e.target.value)}
                        style={{ borderRadius: 10, backgroundColor: '#F1F1F1' }}
                    />

                    <label htmlFor="vendorImage">Vendor Image</label> 
                    <input
                        type='file'
                        accept='image/*'
                        required
                        onChange={e => setVendorImage(e.target.files[0])}
                        style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%' }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                            <label for="password">Password</label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    placeholder='Enter your password'
                                    value={vendorpassword}
                                    required
                                    onChange={e => setPassword(e.target.value)}
                                    style={{
                                        ...inputStylePassword,
                                        borderRadius: 10,
                                        backgroundColor: '#F1F1F1',
                                        paddingRight: `${screenHeight * (24 / 1024)}px`
                                    }}
                                />
                                <div
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        height: `${screenHeight * (22 / 1024)}px`,
                                        width: `${screenHeight * (24 / 1024)}px`,
                                        right: screenWidth * (10 / 1440),
                                        top: '0',
                                        backgroundImage: isPasswordVisible ? `url(${showPassword})` : `url(${hiddenPassword})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        cursor: 'pointer',
                                        marginTop: screenHeight * (9 / 1024),
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                            <label for="password">Confirm Password</label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                                    placeholder='Re-enter your password'
                                    value={confirmPassword}
                                    required
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    style={{
                                        ...inputStylePassword,
                                        borderRadius: 10,
                                        backgroundColor: '#F1F1F1',
                                        paddingRight: `${screenHeight * (24 / 1024) + 10}px`
                                    }}
                                />
                                <div
                                    onClick={toggleConfirmPasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        height: `${screenHeight * (22 / 1024)}px`,
                                        width: `${screenHeight * (24 / 1024)}px`,
                                        right: screenWidth * (10 / 1440),
                                        top: '0',
                                        backgroundImage: isConfirmPasswordVisible ? `url(${showPassword})` : `url(${hiddenPassword})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        cursor: 'pointer',
                                        marginTop: screenHeight * (9 / 1024),
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                    <label for="companyDescription">Vendor Description</label>
                    <textarea
                        type="text"
                        placeholder='Type here'
                        value={vendorDescription}
                        rows={5}
                        required
                        onChange={e => setVendorDescription(e.target.value)}
                        style={{
                            textAlign: 'left',
                            paddingTop: 0,
                            borderRadius: 10,
                            backgroundColor: '#F1F1F1',
                            marginBottom: screenHeight * (24 / 1024),
                            height: screenHeight * (108 / 1024),
                            width: '100%',
                            padding: `${screenHeight * (10 / 1024)}px`,
                            resize: 'none'
                        }}
                    />

                    <button
                        className={(
                            vendorName &&
                            vendorAddress &&
                            vendorNumber &&
                            vendorEmail &&
                            vendorpassword &&
                            confirmPassword &&
                            vendorDescription &&
                            vendorImage) ? 'gradient-color1' : 'base-color'}

                        style={{
                            borderRadius: 20,
                            cursor: 'pointer',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                            color: (
                                vendorName &&
                                vendorAddress &&
                                vendorNumber &&
                                vendorEmail &&
                                vendorpassword &&
                                confirmPassword &&
                                vendorDescription &&
                                vendorImage) ? '#FFFFFF' : '#C0C0C0'
                        }}
                        type='submit'>Register</button>

                </form>

            </div>
        )
    }

    return (
        <div style={{}}>
            <Navbar />
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#FFFFFF',
                height: screenHeight,
                paddingTop: screenHeight * (100 / 1024),
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    overflowY: 'auto',
                }}>
                    {form1()}
                </div>
            </div>
        </div>
    )
}