import React from 'react';
import { Navbar } from '../Navbar';
import { Vendornavbar } from '../../components/Vendornav';

export const Vendorportal = () => {
  React.useEffect(() => {
    console.log("Vendorportal accessed"); // Debug log
  }, []);

  return (
    <div>
      <Vendornavbar />
    </div>
  )
};
