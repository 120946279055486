import React, { useState, useEffect } from 'react';
import Logo from "../Assets/Rebite Logo (5).png";
import { Link, useNavigate } from "react-router-dom"; // Ensure useNavigate is imported
import { auth } from '../config/firebaseSetup';
import './Navbar.css';

export const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [perms, setPerms] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const navigate = useNavigate(); // Hook to programmatically navigate

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
      setPerms(user ? user.vendorVerify : false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    navigate('/');
    auth.signOut();
  };

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleLogoClick = () => {
    navigate(isLoggedIn ? "/vendorportal" : "/");
  };

  return (
    <nav className="navbar">
      <div className="savor-logo">
        <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={Logo} alt="Rebite Logo" />
        </div>
      </div>
      <button
        className={`hamburger ${isNavExpanded ? 'open' : ''}`}
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        <div></div>
        <div></div>
        <div></div>
      </button>
      <ul className={`navbar-links ${isNavExpanded ? "expanded" : ""}`}>
        <li><Link to="/About" className='link-font'>Our Mission</Link></li>
        <li><Link to="/contact" className='link-font'>Contact</Link></li>

        {!isLoggedIn && <li><Link to="/login" className='link-font'>Login</Link></li>}
        {isLoggedIn && (
          <li>
            <div className="profile-icon">
              <button className="profile-button" onClick={toggleProfileDropdown}>Profile</button>
              <div className={`profile-dropdown ${isProfileOpen ? 'active' : ''}`}>
                <ul>
                  <li><Link to="/dashboard" className='link-font'>Dashboard</Link></li>
                  <li><Link to="/settings" className='link-font'>Settings</Link></li>
                  <li><button onClick={handleLogout} className='link-font'>Logout</button></li>
                </ul>
              </div>  
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
};
