import config from '../config/config'
const { fdb, auth, storage } = require('../config/firebaseSetup');

export const createMenuFromFile = async (menuImageFile) => {
    console.log("DO WE GET TO THIS POINT")
    console.log(menuImageFile)
    const url = `${config.apiBaseUrl}/openAIAPI/createMenuFromFile`
    console.log("check this menu upload endpoint")
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`, 
        },
        body: menuImageFile
    })

    if (response.ok) {
        console.log("Response was fine")
        const res = await response.json();
        console.log("here is the res")
        console.log(res)
        return res;
    }
}