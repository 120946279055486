import React from 'react';
import Logo from '../Assets/rebite logo large.png';
import './loading.css';

const LoadingAnimation = () => {
    return (
        <div className="loading-overlay">
            <div className="loading-content">
                <img src={Logo} alt="Rebite Logo" className="loading-logo" />
                <div className="loading-spinner"></div>
                <p className="loading-text">Completing your task...</p>
            </div>
        </div>
    );
};

export default LoadingAnimation;
