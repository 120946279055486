import React, { useEffect, useState, useRef } from 'react';
import { Navbar } from './Navbar';
import { Link } from 'react-router-dom';
import './Home.css';

// Importing images
import homeImage1 from '../Assets/phoneHomeScreen.png'; 
import homeImage2 from '../Assets/pizzaKingApp.png';
import marketing from '../Assets/notification.png';
import sustainability from '../Assets/sustainibility.png';
import costBenefits from '../Assets/cost.png';
import efficiency from '../Assets/time.png';
import Logo from '../Assets/Rebite Logo (5).png'; 

export const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = useRef();

  const slides = [
    {
      img: homeImage1,
      title: 'Discover the deals',
      text: 'Get notified on restaurants starting flash sales!',
    },
    {
      img: homeImage2,
      title: 'Finalize your cart',
      text: 'Customize your cart with your favorites',
    },
    {
      img: homeImage1,
      title: 'Proceed to checkout',
      text: 'Our payment processes ensure seamless transactions',
    },
    {
      img: homeImage2,
      title: "Thats it, you're done!",
      text: 'Enjoy your new favorite meal at a discounted price',
    },
  ];

  const totalSlides = slides.length;

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    slideInterval.current = setInterval(nextSlide, 3000); 

    return () => clearInterval(slideInterval.current); 
  }, []);

  // IntersectionObserver to reveal "Why Us" feature items
  useEffect(() => {
    const featureItems = document.querySelectorAll('.feature-item');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    featureItems.forEach(item => observer.observe(item));

    return () => {
      featureItems.forEach(item => observer.unobserve(item)); // Cleanup observer
    };
  }, []);

  return (
    <div className='landing-page'>
      <Navbar />
      <div className='images-text-container'>
        <div className='text-section'>
          <h1 className='gradient-text'>Bite into Better Deals Today!</h1>
          <p>Rebite is meant to help connect users with local restaurants through flash sales to promote consistent restaurant revenue and food sustainability</p>
          <div className='sign-up-button'>
            <Link to="https://apps.apple.com/us/app/rebite/id6618117899" className="button-link" target="_blank" rel="noopener noreferrer">Join Rebite</Link>
          </div>
        </div>
        <div className='home-banner-images'>
          <img src={homeImage1} alt='homeImage1' className='home-banner-image' />
          <img src={homeImage2} alt='homeImage2' className='home-banner-image' />
        </div>
      </div>

      <div className='why-us-section'>
        <h1>Why Rebite?</h1>
        <div className='features-container'>
          <div className='feature-item'>
            <img src={marketing} alt="Marketing" className="feature-image" />
            <div className='feature-text'>
              <h2>Marketing</h2>
              <p>Increase local market reach through push notifications</p>
            </div>
          </div>
          <div className='feature-item'>
            <img src={sustainability} alt="Sustainability" className="feature-image" />
            <div className='feature-text'>
              <h2>Sustainability</h2>
              <p>Reduction in food waste & boost in restaurant revenue</p>
            </div>
          </div>
          <div className='feature-item'>
            <img src={costBenefits} alt="Benefits" className="feature-image" />
            <div className='feature-text'>
              <h2>Cost Benefits</h2>
              <p>Additional ways to increase revenue during slow periods</p>
            </div>
          </div>
          <div className='feature-item'>
            <img src={efficiency} alt="Efficiency" className="feature-image" />
            <div className='feature-text'>
              <h2>Efficiency</h2>
              <p>Easy-to-use interface for restaurants to create and manage flash sales</p>
            </div>
          </div>
        </div>
      </div>

      {/* How to Use the App section with automated sliding and dot navigation */}
      {/* <div className="how-to-use-section">
        <h1>How to Use the App</h1>
        <div className="how-to-use-container">
          <div className="how-to-use-image">
            <img src={slides[currentSlide].img} alt="App Demo" className="demo-image" />
          </div>
          <div className="how-to-use-text">
            <h2>{slides[currentSlide].title}</h2>
            <p>{slides[currentSlide].text}</p>
          </div>
          <div className="dots-indicator">
            {slides.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
              ></span>
            ))}
          </div>
        </div>
      </div> */}

      {/* Footer Section */}
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={Logo} alt="Rebite Logo" className="footer-logo-image" />
            <p>contact@getrebite.com</p>
            <p>© 2024 Rebite. All rights reserved.</p>
          </div>
          <div className="footer-links">
            <div className="footer-column">
              <p><Link to="/download-rebite">Download Rebite</Link></p>
              <p><Link to="/for-vendors">For Vendors</Link></p>
            </div>
            <div className="footer-column">
              <p><Link to="/terms-and-conditions">Terms and Conditions</Link></p>
              <p><Link to="/privacy-policy">Privacy Policy</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
