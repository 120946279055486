import React, { useState, useEffect } from 'react';
import { fdb, auth } from '../../config/firebaseSetup';
import { Vendornavbar } from '../../components/Vendornav';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, collection, arrayUnion, writeBatch, updateDoc, setDoc } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from 'react-modal';
import './CreateMenuItem.css';
import { fetchMenuItems } from '../../API/menuAPI';
import { addMenuItems } from '../../API/menuAPI';
import { saveUpdatedItem } from '../../API/menuAPI';
import { deleteMenuItem } from '../../API/menuAPI';
import { getVendorName } from '../../API/vendorAPI';
import { createMenuFromFile } from '../../API/openAIAPI';
import placeholderImage from '../../Assets/placeholder.png';
import LoadingAnimation from '../loading';

export const CreateMenuItem = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productDescription, setProductDescription] = useState(''); 
    const [image, setImage] = useState(null); 
    const [imagePreview, setImagePreview] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const [vendorName, setVendorName] = useState('');
    const [mealType, setMealType] = useState('Breakfast');
    const [items, setItems] = useState([]);
    const [menuItems, setMenuItems] = useState({
        Breakfast: [],
        Lunch: [],
        Dinner: [],
        Drinks: [],
        Dessert: []
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(null); 
    const [menuImage, setMenuImage] = useState(null);
    const [isProcessingImage, setIsProcessingImage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setVendorId(user.uid);
                fetchVendorName();
                fetchsetMenuItems();
            } else {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const fetchsetMenuItems = async () => {
        try {
            const fetchedMenuItems = await fetchMenuItems();
            // Ensure we always have a valid object with arrays
            setMenuItems({
                Breakfast: fetchedMenuItems?.Breakfast || [],
                Lunch: fetchedMenuItems?.Lunch || [],
                Dinner: fetchedMenuItems?.Dinner || [],
                Drinks: fetchedMenuItems?.Drinks || [],
                Dessert: fetchedMenuItems?.Dessert || []
            });
        } catch (error) {
            console.error('Failed to fetch menu items:', error);
            // Keep the default empty arrays on error
        }
    };

    const addsetMenuItems = async () => {
        try {
            const result = await addMenuItems(items);
            if (result) {
                console.log("Yes this worked")
                const newMenuItems = { ...menuItems };
                items.forEach(item => {
                    if (newMenuItems[item.mealType]) {
                        newMenuItems[item.mealType].push(item);
                    } else {
                        newMenuItems[item.mealType] = [item];
                    }
                });
                setMenuItems(newMenuItems);
                setItems([]);
                setModalIsOpen(false);
            } else {
                alert("Error adding menu items");
            }
        } catch (error) {
            console.error("Error adding and update new menu items: ", error);
        }
    };

    const fetchVendorName = async () => {
        try {
            const fetchedVendorName = await getVendorName();
            setVendorName(fetchedVendorName);
        } catch (error) {
            console.error('Failed to fetch vendor name: ', error)
        }
    };

    const handleAddMore = () => { 
        if(productName && productPrice && productDescription && (image || imagePreview) && mealType){ 
            const newItem = {
                name: productName,
                price: parseFloat(productPrice),
                description: productDescription,
                mealType: mealType, 
                image: image
            };
            setItems(prevItems => [...prevItems, newItem]);
            resetFormFields();
        } else {
            alert("All fields must be filled");
        }
    }; 

    const uploadImage = async (file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `menu-items/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };   

    const getPlaceholderImageFile = async () => {
        const response = await fetch(placeholderImage);
        const blob = await response.blob();
        return new File([blob], "placeholder.png", { type: "image/png" });
    };

    const resetFormFields = () => {
        setProductName('');
        setProductPrice('');
        setProductDescription('');
        setMealType('Breakfast'); 
        setImage(null); 
        setImagePreview(null);
    };

    const handleUpdateItem = (item, index) => {
        setProductName(item.name);
        setProductPrice(item.price);
        setProductDescription(item.description);
        setMealType(item.mealType);
        setImagePreview(item.img);
        setIsUpdating(true);
        setItemToUpdate({ ...item, index });
        setModalIsOpen(true);
    };

    const updatesetUpdatedItem = async () => {
        if (!vendorId || !itemToUpdate) {
            alert("Vendor ID and item to update are required.");
            return;
        }
        const updatedItem = {
            name: productName,
            price: parseFloat(productPrice),
            description: productDescription,
            mealType: mealType,
            img: itemToUpdate.img,
            imageBool: image,
            index: itemToUpdate.index,
            oldType: itemToUpdate.mealType
        };
        try {
            const updatedItems = await saveUpdatedItem(updatedItem);
            if (updatedItems) {
                setMenuItems(prevMenuItems => ({
                    ...prevMenuItems,
                    [itemToUpdate.mealType]: updatedItems
                }));
                setModalIsOpen(false);
                resetFormFields();
                setIsUpdating(false);
                setItemToUpdate(null);
            } else {
                alert("Error updating menu items");
            }
        } catch (error) {
            console.error("Error updating menu items: ", error);
        }
    };

    const deletesetMenuItem = async (item) => {
        if (!vendorId) {
            alert("Vendor ID is required.");
            return;
        }
        try {
            const updatedItems = await deleteMenuItem(item);
            if (updatedItems) {
                setMenuItems(prevMenuItems => ({
                    ...prevMenuItems,
                    [item.mealType]: updatedItems
                }));
            } else {
                alert("Error deleting menu item");
            }
        } catch (error) {
            console.error("Error deleting menu item: ", error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isUpdating) {
            updatesetUpdatedItem();
        } else {
            addsetMenuItems();
        }
    };

    const handleRemoveItem = (index) => {
        setItems(prevItems => prevItems.filter((_, i) => i !== index));
    }; 

    const handleMenuImageUpload = async (event) => { 
        const file = event.target.files[0];
        if (!file) return;
        
        setMenuImage(file);
        setIsProcessingImage(true); 

        const menuImageFile = new FormData();
        menuImageFile.append('menuImage', file);

        try {
            const response = await createMenuFromFile(menuImageFile);
            if (response) {
                await addMenuItems(response);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error processing menu image:', error);
            alert(`Failed to process menu image. Error: ${error.message}`);
        } finally {
            setIsProcessingImage(false);
        }
    };

    const isMenuEmpty = !menuItems || 
        Object.values(menuItems).every(category => 
            Array.isArray(category) && category.length === 0
        );

    return (
        <div>
             <Vendornavbar />
                {isSubmitting && <LoadingAnimation />}
                {menuItems && Object.keys(menuItems).length > 0 && Object.values(menuItems).some(items => items.length > 0) && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px', marginBottom: '20px' }}>
                        <button onClick={() => setModalIsOpen(true)} className='gradient-color'>
                            Create Menu Item
                        </button> 
                    </div>
            )  }
            <div className="menu-items-list">
                {Object?.values(menuItems).length > 0 ? Object?.keys(menuItems).map((type, index, array) => (
                    menuItems[type].length > 0 && (
                        <div key={type} className="menu-category-card">
                            <h3 className="menu-category-title">{type}</h3>
                            <div className="card-container">
                                {menuItems[type].map((item, itemIndex) => (
                                    <div key={itemIndex} className="menu-card">
                                        <button onClick={() => deletesetMenuItem(item)} className='delete-button-top'>X</button>
                                        {item.img && <img src={item.img} alt={item.name} className="menu-card-image"/>}
                                        <div className="menu-card-content">
                                            <h3>{item.name}</h3>
                                            <p>{isNaN(parseFloat(item.price)) ? "Price not available" : `$${parseFloat(item.price).toFixed(2)}`}</p>
                                            <p>{item.description}</p>
                                            <button onClick={() => handleUpdateItem(item, itemIndex)} className='gradient-color update-button'>Update</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )) : <p>No menu items available.</p>}
            </div>
            {isMenuEmpty && (
                <div className="center-container">
                    <div className="upload-card">
                        <h2>Express Onboarding</h2>
                        <div className="upload-instructions">
                            <p>Upload menu here (only accepts jpeg,png,pdf) </p>
                            <input
                                type="file"
                                id="menuImage"
                                onChange={handleMenuImageUpload}
                                accept="image/*, jpeg,png"
                                disabled={isProcessingImage}
                            />
                        </div>
                        {isProcessingImage && <LoadingAnimation />}
                    </div>
                    <button onClick={() => setModalIsOpen(true)} className='gradient-color'>
                        Create Menu Item
                    </button> 
                </div>   
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => {
                    setModalIsOpen(false);
                    resetFormFields();
                    setIsUpdating(false);
                    setItemToUpdate(null);
                    setIsSubmitting(false)
                }}
                contentLabel="Create Menu Item"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    content: {
                        border: 'none'
                    }
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', overflowY: 'auto' }}>
                    <div style={{
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        backgroundColor: '#FFFFFF',
                        padding: '20px',
                        margin: '20px',
                        width: '80%',
                        height: 'auto',
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <h1 style={{ fontSize: '18px', fontWeight: 'bold', paddingTop: '10px' }}>Create a new menu item</h1>
                            <button style={{ fontSize: '24px', border: 'none', backgroundColor: '#FFFFFF', cursor: 'pointer' }} onClick={() => setModalIsOpen(false)}>X</button>
                        </div>
                        <div style={{ border: '0.5px solid #ccc', margin: '23px 0' }} />
                        <form onSubmit={handleSubmit} className='create-menu-item-form'>
                            <label htmlFor="mealType" style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Menu Information</label>
                            <select
                                value={mealType}
                                onChange={e => setMealType(e.target.value)}
                                
                                style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%', height: screenHeight * (40 / 1024), marginBottom: screenHeight * (20 / 1024) }}
                            >
                                <option value="Breakfast">Breakfast</option>
                                <option value="Lunch">Lunch</option>
                                <option value="Dinner">Dinner</option>
                                <option value="Drinks">Drinks</option>
                                <option value="Dessert">Dessert</option>
                            </select>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input
                                    type='text'
                                    value={productName}
                                    placeholder='Menu item name'
                                    onChange={e => setProductName(e.target.value)}
                                   
                                    style={{
                                        borderRadius: 10,
                                        backgroundColor: '#F1F1F1',
                                        paddingRight: `${screenHeight * (24 / 1024)}px`
                                    }}
                                />
                                <input
                                    type='number'
                                    value={productPrice}
                                    placeholder='Price'
                                    onChange={e => setProductPrice(e.target.value)}
                                    
                                    style={{
                                        borderRadius: 10,
                                        backgroundColor: '#F1F1F1',
                                        paddingRight: `${screenHeight * (24 / 1024)}px`
                                    }}
                                />
                            </div>
                            <input
                                type='text'
                                value={productDescription}
                                placeholder='Description'
                                onChange={e => setProductDescription(e.target.value)}
                                
                                style={{ borderRadius: 10, backgroundColor: '#F1F1F1', width: '100%', height: screenHeight * (40 / 1024), marginBottom: screenHeight * (20 / 1024) }}
                            />
                            <label htmlFor="image" style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Upload Image</label>
                            <input
                                type='file'
                                onChange={e => {
                                    const file = e.target.files[0];
                                    setImage(file);
                                    const previewURL = URL.createObjectURL(file);
                                    setImagePreview(previewURL);
                                }}
                                style={{
                                    padding: '20px',
                                    textAlign: 'center',
                                    marginBottom: '20px',
                                    cursor: 'pointer',
                                    backgroundColor: '#F1F1F1',
                                    borderRadius: '10px',
                                }}
                            />
                            {imagePreview && (
                                <img src={imagePreview} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginBottom: '20px' }} />
                            )}
                            <div style={{ border: '0.5px solid #ccc', margin: '23px 0' }} />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                {isUpdating ? (
                                    <button type='button' onClick={updatesetUpdatedItem} style={{ flex: 1, borderRadius: '25px', border: 'none', cursor: 'pointer' }} className='gradient-color'>Update Item</button>
                                ) : (
                                    <button type='button' onClick={handleAddMore} style={{ flex: 1, borderRadius: '25px', border: 'none', cursor: 'pointer' }} className='gradient-color'>Add</button>
                                )}
                                <button type='submit' style={{ flex: 1, borderRadius: '25px', border: 'none', cursor: 'pointer' }} className='gradient-color'>Submit All Items</button>
                            </div>
                        </form>
                        <div className="items-list">
                            {items.map((item, index) => (
                                <div key={index} className="item-preview" style={{ marginTop: '20px', padding: '10px', backgroundColor: '#F1F1F1', borderRadius: '10px' }}>
                                    <h4>{item.name}</h4>
                                    {item.image && <img src={URL.createObjectURL(item.image)} alt={item.name} style={{ maxWidth: '50px', maxHeight: '50px' }} />}
                                    <p>{item.description}</p>
                                    <p>{isNaN(parseFloat(item.price)) ? "Price not available" : `$${parseFloat(item.price).toFixed(2)}`}</p>
                                    <p>{item.mealType}</p>
                                    <button onClick={() => handleRemoveItem(index)} style={{ backgroundColor: '#D3D3D3', color: 'black', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer' }}>Remove</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
