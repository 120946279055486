import React, { useState, useEffect } from 'react';
import Logo from "../Assets/Rebite Logo (5).png";
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../config/firebaseSetup';
import './Vendornav.css';

export const Vendornavbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [perms, setPerms] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
      setPerms(user ? user.vendorVerify : false);
    });

    return () => unsubscribe();
  }, []);

  //test

  const handleLogout = () => {
    auth.signOut();
    navigate('/');
  };

  const handleLogoClick = () => {
    navigate(isLoggedIn ? "/dashboard" : "/");
  };

  return (
    <nav className={`navbar ${isNavExpanded ? "expanded" : ""}`}>
      <div className="savor-logo">
        <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={Logo} alt="Savor" />
        </div>
      </div>
      <button
        className={`hamburger ${isNavExpanded ? 'open' : ''}`}
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        <div></div>
        <div></div>
        <div></div>
      </button>
      <ul className={`navbar-links ${isNavExpanded ? "expanded" : ""}`}>
        <li><Link to="/dashboard" className='link-font'>Dashboard</Link></li>
        <li><Link to="/orders" className='link-font'>Orders</Link></li>
        <li><Link to='/new-menu-item' className='link-font'>Menus</Link></li>
        {/* <li><Link to="/analytics" className='link-font'>Analytics</Link></li> */}
        <li><Link to="/Flashsales" className='link-font'>Flash Sale</Link></li>
        {isLoggedIn && (
          <li><button onClick={handleLogout} className='link-font'>Logout</button></li>
        )}
        {!isLoggedIn && <li><Link to="/login" className='link-font'>Login</Link></li>}
      </ul>
    </nav>
  );
};
