import React, { useState } from 'react'
import { Navbar } from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { doc, getDoc, getDocs, collection, arrayUnion, writeBatch, updateDoc, setDoc } from "firebase/firestore";
import { fdb, auth } from '../config/firebaseSetup'
import emailSymb from '../Assets/emailSymbol.png'
import passwordSymb from '../Assets/passwordSymb.png'
import hiddenPassword from '../Assets/hiddenPassword.png'
import showPassword from '../Assets/showPassword.png'
import './Login.css';

export const Login = () => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  //const [ifHiddenPassword, setIfHiddenPassword] = useState({ hiddenPassword });
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [ifVerified, setIfVerified] = useState(false);
  const navigate = useNavigate();

  /*   screenWidth * (x/1440)
    screenHeight * (x/1024) */

  const login = e => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        vendorVerified().then(veri => {
          console.log("veri: ", veri)
          if (veri) {
            if (!response.user.emailVerified) {
              sendEmailVerification(auth.currentUser)
                .then(() => {
                  setError("Please verify your email. A verification link has been sent.");
                  setLoading(false);
                  navigate('/verify-email');
                })
                .catch(err => {
                  setError(err.message);
                  setLoading(false);
                });
            } else {
              console.log(auth.currentUser.uid)
              console.log("justin check this id token")
              console.log(auth.currentUser.getIdToken)
              navigate('/dashboard');
            }
          } else {
            auth.signOut().then(() => navigate('/'));
          }
        }).catch(error => {
          console.error("error: ", error)
          setLoading(false);
        })
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  };

  console.log("Shreyaj Bhandari")

  const vendorVerified = async () => {
    const vendorRef = doc(fdb, "Vendors", auth.currentUser.uid);
    const vendorDoc = await getDoc(vendorRef)
    if (vendorDoc.exists()) {
      const verifyVendor = vendorDoc.data().vendorVerify;
      console.log("Check here")
      console.log(verifyVendor)
      if (verifyVendor == true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };


  const inputStyleEmail = {
    width: (screenHeight <= screenWidth) ? `${screenWidth * (340 / 1440)}px` : `${screenWidth * (340 / 393)}px`,
    height: `${screenHeight * (40 / 1024)}px`,
    paddingLeft: (screenHeight <= screenWidth) ? `${screenWidth * (30 / 1440)}px` : `${screenWidth * (30 / 393)}px`,
    backgroundImage: `url(${emailSymb})`,
    backgroundPosition: `${screenHeight * (10 / 1024)}px center`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${screenHeight * (20 / 1024)}px ${screenHeight * (20 / 1024)}px`,
    borderRadius: '10px',
    backgroundColor: '#F1F1F1'
  };

  const inputStylePassword = {
    width: (screenHeight <= screenWidth) ? `${screenWidth * (340 / 1440)}px` : `${screenWidth * (340 / 393)}px`,
    height: `${screenHeight * (40 / 1024)}px`,
    paddingLeft: (screenHeight <= screenWidth) ? `${screenWidth * (30 / 1440)}px` : `${screenWidth * (30 / 393)}px`,
    backgroundImage: `url(${passwordSymb})`,
    backgroundPosition: `${screenHeight * (10 / 1024)}px center`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${screenHeight * (20 / 1024)}px ${screenHeight * (20 / 1024)}px`,
    borderRadius: '10px',
    backgroundColor: '#F1F1F1'
  };

  return (
    <div style={{ backgroundColor: '#FFFFFF', height: screenHeight }} >
      <Navbar />
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', paddingTop: screenHeight * (300 / 1024) }} >
        <div>
          <h1 style={{ color: '#FF6E41', fontSize: screenHeight * (75 / 1024) }}>Welcome!</h1>
          {error && <div className='auth__error'>{error}</div>}
          <form onSubmit={login} name='login_form'>
            <div style={{ fontSize: screenHeight * (15 / 1024), fontWeight: 'bold' }}>Email</div>
            <div style={{ position: 'relative' }}>
              <input
                type='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
                style={inputStyleEmail}
              />
            </div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
              <div style={{ fontSize: screenHeight * (15 / 1024), fontWeight: 'bold' }}>Password</div>
              <Link style={{ color: '#FF6E41' }} to='/PasswordChange'>Forgot password?</Link>
            </div>
            <div style={{ position: 'relative' }}>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
                placeholder='Enter your password'
                style={{
                  ...inputStylePassword,
                  backgroundImage: `url(${passwordSymb})`,
                  backgroundPosition: `${screenHeight * (10 / 1024)}px center`,
                  backgroundSize: `${screenHeight * (20 / 1024)}px ${screenHeight * (20 / 1024)}px`,
                }}
              />
              <div
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  height: `${screenHeight * (22 / 1024)}px`,
                  width: `${screenHeight * (24 / 1024)}px`,
                  right: (screenHeight <= screenWidth) ? screenWidth * (10 / 1440) : screenWidth * (10 / 393),
                  top: '0',
                  backgroundImage: isPasswordVisible ? `url(${showPassword})` : `url(${hiddenPassword})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  cursor: 'pointer',
                  marginTop: screenHeight * (9 / 1024),
                }}
              />
              {/* <img src={emailSymb} style={{ height: screenHeight * (22 / 1024), width: screenWidth * (20 / 1440) }} /> */}
            </div>
            <button
              className={(email && password) ? 'gradient-color1' : 'base-color'}
              style={{
                padding: screenHeight * (10 / 1024),
                color: (email && password) ? '#FFFFFF' : '#C0C0C0',
                borderRadius: 10, cursor: 'pointer'
              }}
              type='submit'
              disabled={loading}>
              {loading ? 'Signing In...' : 'Sign in'}
            </button>
          </form>
          <p style={{ fontSize: screenHeight * (15 / 1024), paddingTop: screenHeight * (10 / 1024), color: '#787878' }}>
            Don't have an account? <Link style={{ color: '#FF6E41' }} to='/vendor-reg'>Create one here</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
