import React, { useState, useEffect } from 'react';
import { Vendornavbar } from '../../components/Vendornav';
import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import { fdb, auth } from '../../config/firebaseSetup';
import { fetchVendorFlashSales } from '../../API/flashsaleAPI';
import { getVendorName } from '../../API/vendorAPI';
import Logo from '../../Assets/rebite logo large.png';
import StripeLogo from '../../Assets/Stripe Logo.png';
import './Dashboard.css';

// Define the base URL for your API
const API_BASE_URL = 'https://server.getrebite.com';


export const Dashboard = () => {
  const [storeInfo, setStoreInfo] = useState(null);
  const [formValues, setFormValues] = useState({
    owner: '',
    location: '',
    contact: ''
  });
  const [editMode, setEditMode] = useState(false);

  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
  const [error, setError] = useState(null);
  const [connectedAccountId, setConnectedAccountId] = useState(null);
  //const [ifStripeId, setIfStripeId] = useState(false);
  const [initialOnboardingCompleted, setInitialOnboardingCompleted] = useState(false);



  useEffect(() => {
    const fetchStoreInfo = async () => {
      const user = auth.currentUser;
      console.log(user)
      if (user) {
        const response = await fetchVendorFlashSales(user.uid);
        console.log("Did the request go through: " + response);
        console.log("Does this work: ")
        const vendorRef = doc(fdb, "Vendors", user.uid);
        const vendorDoc = await getDoc(vendorRef);
        console.log(vendorDoc.data())
        if (vendorDoc.exists()) {
          setStoreInfo(vendorDoc.data());
          setFormValues({
            owner: vendorDoc.data().owner,
            location: vendorDoc.data().location,
            contact: vendorDoc.data().contact
          });
        }
      }
    };

    fetchStoreInfo();

  }, []);

  // const addStripeId = async (connectedAccountId) => {
  //   const user = auth.currentUser
  //   if (user) {
  //     try {
  //       const vendorsRef = doc(fdb, "Vendors", user.uid);
  //       const inVendorsRef = await getDoc(vendorsRef);
  //       if (inVendorsRef.exists()) {
  //         const data = inVendorsRef.data();
  //         if (!data.stripeId) {
  //           await updateDoc(vendorsRef, {
  //             stripeId: connectedAccountId,
  //           })
  //           setIfStripeId(true);
  //           console.log("added successfully")
  //         } else {
  //           setIfStripeId(true);
  //           console.log("stripeId already exists")
  //         }
  //       }
  //     } catch (e) {
  //       console.log(e)
  //     }
  //   }
  // }

  const getInitialOnboarding = async () => {
    const user = auth.currentUser;
    if (user) {
      const vendorsRef = doc(fdb, "Vendors", user.uid);
      const inVendorsRef = await getDoc(vendorsRef);
      if (inVendorsRef.exists()) {
        const data = inVendorsRef.data();
        return data.initialOnboarding || false;
      }

    }
    return false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      const vendorRef = doc(fdb, "Vendors", user.uid);
      try {
        await updateDoc(vendorRef, formValues);
        alert("Store information updated successfully!");
        setStoreInfo(formValues);
        setEditMode(false);
      } catch (error) {
        console.error("Error updating store information:", error);
        alert("Failed to update store information. Please try again.");
      }
    }
  };

  // const createAccount = async () => {
  //   if (ifStripeId) {
  //     console.log("stripe account exists")
  //     return;
  //   } else {
  //     setAccountCreatePending(true);
  //     setError(null);
  //     try {
  //       const response = await fetch(`${API_BASE_URL}/account`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       const json = await response.json();
  //       if (json.account) {
  //         setConnectedAccountId(json.account);
  //         addStripeId(json.account)
  //       } else if (json.error) {
  //         throw new Error(json.error);
  //       }
  //     } catch (e) {
  //       setError(`Failed to create account: ${e.message}`);
  //     } finally {
  //       setAccountCreatePending(false);
  //     }
  //   }
  // };

  // const createAccountLink = async () => {
  //   setAccountLinkCreatePending(true);
  //   setError(null);
  //   try {
  //     const response = await fetch(`${API_BASE_URL}/account_link`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         account: connectedAccountId,
  //       }),
  //     });
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
  //     }
  //     const json = await response.json();
  //     if (json.url) {
  //       window.location.href = json.url;
  //     } else if (json.error) {
  //       throw new Error(json.error);
  //     }
  //   } catch (e) {
  //     console.error('Detailed error:', e);
  //     setError(`Failed to create account link: ${e.message}`);
  //   } finally {
  //     setAccountLinkCreatePending(false);
  //     setInitialOnboardingCompleted(true);
  //   }
  // };

  return (
    <div className='dashboard-page'>
      <div className="dashboard-container">
        <Vendornavbar />
        <div className="dashboard-content">
          <h2 className='dash-welcome-text'>Welcome to Your Dashboard, {storeInfo && storeInfo.vendorName}</h2>
          {storeInfo && (
            <div className='store-info-section'>
              <h3 className='header-store-info'>Store Information</h3>
              {!editMode ? (
                <div>
                  <div className='store-info'>
                    <p className='info-name'>Owner:</p>
                    <p className='info-value'>{storeInfo.owner}</p>
                  </div>
  
                  <div className='store-info'>
                    <p className='info-name'>Location:</p>
                    <p className='info-value'>{storeInfo.location}</p>
                  </div>
  
                  <div className='store-info'>
                    <p className='info-name'>Contact:</p>
                    <p className='info-value'>{storeInfo.contact}</p>
                  </div>
                  <button className='edit-vendor-info-button' onClick={() => setEditMode(true)}>Edit Vendor Info</button>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <label htmlFor="owner">Owner:</label>
                  <input
                    type="text"
                    name="owner"
                    value={formValues.owner}
                    onChange={handleChange}
                  />
                  <label htmlFor="location">Location:</label>
                  <input
                    type="text"
                    name="location"
                    value={formValues.location}
                    onChange={handleChange}
                  />
                  <label htmlFor="contact">Contact:</label>
                  <input
                    type="text"
                    name="contact"
                    value={formValues.contact}
                    onChange={handleChange}
                  />
                  <button type="submit">Update Information</button>
                  <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
                </form>
              )}
            </div>
          )}
        </div>
        </div>
      </div>
    );
  };

  // {!initialOnboardingCompleted ? (
  //   <div className="stripe-onboarding-card">
  //     <div className="container">
  //       <div className="banner">
  //         <img src={Logo} alt="Rebite Logo" className="logo" />
  //         <span className="logo-x">X</span>
  //         <img src={StripeLogo} alt="Stripe Logo" className="stripe-logo"/>
  //       </div>
  //       <div className="content">
  //         {!connectedAccountId && <h2>Get ready for some sweet deals!</h2>}
  //         {!connectedAccountId && <p>Rebite Inc. is here to help you sell your product</p>}
  //         {connectedAccountId && <h2>Add information to start accepting money</h2>}
  //         {connectedAccountId && <p>Rebite Inc. partners with Stripe to help you receive payments and keep your personal bank and details secure.</p>}
  //         {!accountCreatePending && !connectedAccountId && !ifStripeId && (
  //           <button className="create-account-button" onClick={createAccount}>
  //             Create an account!
  //           </button>
  //         )}
  //         {connectedAccountId && !accountLinkCreatePending && ifStripeId && (
  //           <button className="add-information-button" onClick={createAccountLink}>
  //             Add information
  //           </button>
  //         )}
  //         {error && <p className="error">{error}</p>}
  //         {(connectedAccountId || accountCreatePending || accountLinkCreatePending) && (
  //           <div className="dev-callout">
  //             {connectedAccountId && <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
  //             {accountCreatePending && <p>Creating a connected account...</p>}
  //             {accountLinkCreatePending && <p>Creating a new Account Link...</p>}
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // ) : (
  //   <p>Account set up!</p>
  // )}