import React from 'react';
import { Navbar } from './Navbar';
import './About.css';
import Logo from '../Assets/Rebite Logo (5).png';
import placeholder from '../Assets/rebite logo large.png';
import efficiency from '../Assets/timeblack.png';

export const AboutUs = () => {
  return (
    <div className='about-page'>
      <Navbar />
      <div className='about-hero-section'>
        <h1>Our Mission</h1>
        <h2 className="gradient-text">Reducing Food Waste by Increasing Sales, One Local Restaurant at a Time</h2>
        <p>Rebite is more than just a food app. Our goal is to drive down the global food waste total by giving power back to the restaurants, helping them sell their delicious food options to the public so everyone wins.</p>
        <div className='sign-up-button'>
          <a href="/vendor-reg" className="button-link">Join Rebite</a>
        </div>
      </div>

      <div className='values-section'>
        <h2>Our Values</h2>
        <div className='values-container'>
          <div className='value-card'>
            <img src={efficiency} alt="clock" className="value-icon" />
            <h3>Helping Small Businesses</h3>
            <p>Our app is for the local restaurants present in every town that need a place to showcase their affordable food deals. Rebite’s easy-to-use dashboard allows for unique marketing and more daily revenue.</p>
          </div>
          <div className='value-card'>
            <img src={efficiency} alt="clock" className="value-icon" />
            <h3>Promoting efficiency</h3>
            <p>1/5 of the food produced every day is wasted, contributing to millions of tons of food waste each year. Rebite works to solve this, giving restaurants the option to offer affordable food to price-sensitive customers.</p>
          </div>
          <div className='value-card'>
            <img src={efficiency} alt="clock" className="value-icon" />
            <h3>Maximizing Convenience</h3>
            <p>We understand that restaurant owners live busy lives attending to customers. That’s why we built the technology to onboard any restaurant menu in seconds, so you can get started immediately.</p>
          </div>
        </div>
      </div>


      <div className="clients-section">
      {/* Statistics Section */}
      <div className="stats-container">
        <div className="stat-card">
          <h3>25</h3>
          <p>Restaurants Onboarded</p>
        </div>
        <div className="stat-card">
          <h3>200+</h3>
          <p>User Downloads</p>
        </div>
        <div className="stat-card">
          <h3>4</h3>
          <p>Different States Impacted</p>
        </div>
      </div>

      {/* Clients Section */}
      {/* <h2>Our Clients</h2>
      <div className="clients-container">
        {/* Using the logo as placeholders */}
        {/* <img src={placeholder} alt="client-logo" className="client-logo" /> */}
        {/* <img src={placeholder} alt="client-logo" className="client-logo" />
        <img src={placeholder} alt="client-logo" className="client-logo" />
        <img src={placeholder} alt="client-logo" className="client-logo" />
        <img src={placeholder} alt="client-logo" className="client-logo" />
        <img src={placeholder} alt="client-logo" className="client-logo" />
      </div> */} 
    </div>

      {/* Footer Section */}
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={Logo} alt="Rebite Logo" className="footer-logo-image" />
            <p>contact@getrebite.com</p>
            <p>© 2024 Rebite. All rights reserved.</p>
          </div>
          <div className="footer-links">
            <div className="footer-column">
              <p><a href="/download-rebite">Download Rebite</a></p>
              <p><a href="/for-vendors">For Vendors</a></p>
            </div>
            <div className="footer-column">
              <p><a href="/terms-and-conditions">Terms and Conditions</a></p>
              <p><a href="/privacy-policy">Privacy Policy</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//test comment