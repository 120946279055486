import config from '../config/config'
const { fdb, auth, storage } = require('../config/firebaseSetup');

export const getVendorName = async () => {
    console.log("i hit fetch vname")
    const url = `${config.apiBaseUrl}/vendorAPI/getVendorName`
    var token = await auth.currentUser.getIdToken();
    console.log("I have this token in fetch name :" + token)
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    if (response.ok) {
        console.log("fetch all flash sales worked!");
        const res = await response.json();
        return res;
    }
} 

export const getVendorOrders = async() =>{
    console.log("I hit the get vendor orders") 
    const url = `${config.apiBaseUrl}/vendorAPI/getVendorOrders` 
    var token = await auth.currentUser.getIdToken(); 
    const response = await fetch(url,{
        method:'GET', 
        headers:{
            'Authorization':`Bearer ${token}`, 
            'Content-Type': 'application/json'
        }
    }) 
    if (response.ok){
        console.log("Fetch all of the vendor orders") 
        const res = await response.json() 
        return res
    }
}