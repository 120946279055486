import { useState, useEffect } from 'react';
import { auth } from '../config/firebaseSetup';
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { fdb } from '../config/firebaseSetup';

export const VerifyEmail = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [time, setTime] = useState(10); // Timer for resend button cooldown
  const [timeActive, setTimeActive] = useState(true); // Controls if timer is active
  const navigate = useNavigate();

  // Subscribe to auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user && user.emailVerified) {
        updateVendorVerification(user); // Call to update Firestore
      }
    });
    return () => unsubscribe(); // Cleanup subscription
  }, []);

  // Timer to handle resend button cooldown
  useEffect(() => {
    let interval = null;
    if (timeActive && time > 0 && currentUser && !currentUser.emailVerified) {
      interval = setInterval(() => {
        currentUser.reload().then(() => {
          if (currentUser.emailVerified) {
            clearInterval(interval);
            updateVendorVerification(currentUser); // Call to update Firestore
            navigate('/vendorportal'); // Navigate home or to a confirmation page
          }
        }).catch((err) => {
          console.error("Error reloading user:", err);
          alert(err.message);
        });
        setTime((prevTime) => prevTime - 1); // Decrement timer
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(10); // Reset timer after cooldown
    }
    return () => clearInterval(interval); // Cleanup interval
  }, [timeActive, time, currentUser, navigate]);

  // Function to update the Firestore
  const updateVendorVerification = async (user) => {
    const vendorRef = doc(fdb, "Vendors", user.uid); // Ensure correct document path
    try {
      await updateDoc(vendorRef, {
        vendorVerify: true
      });
    } catch (error) {
      console.error("Error updating vendor verification:", error);
    }
  };

  // Function to resend verification email
  const resendEmailVerification = () => {
    if (currentUser) {
      sendEmailVerification(currentUser)
        .then(() => {
          console.log("Verification email resent.");
          setTimeActive(true); // Reactivate timer for cooldown
        })
        .catch((err) => {
          console.error("Error resending verification email:", err);
          alert(err.message);
        });
    } else {
      alert("No user is currently signed in.");
    }
  };

  return (
    <div className='center'>
      <div className='verifyEmail'>
        <h1>Verify your Email Address</h1>
        <p>
          <strong>A Verification email has been sent to:</strong><br />
          <span>{currentUser?.email}</span>
        </p>
        <span>Follow the instruction in the email to verify your account.</span>
        <button
          onClick={resendEmailVerification}
          disabled={timeActive}
        >Resend Email {timeActive && `in ${time} seconds`}</button>
      </div>
    </div>
  );
};