import React, { useState, useEffect } from 'react'; 
import { auth } from '../../config/firebaseSetup';
import { Vendornavbar } from '../Vendornav' 
import { getVendorOrders } from '../../API/vendorAPI'  
import './Orders.css';


export const Orders = () => {  

  // We need to fetch all the orders from the vendor collection (Orders collection and completedOrders collection)  

  //Populate a table with the order information (userName,Items,orderTime) (filter so that the inocmeplete orders are at the top) 

  // for completed orders show the time of order completion and for incompelet orders have a button that allows you to mark an order as comeplete and refreshes upon update  

  const [completedOrders, setCompletedOrders] = useState([]);
  const [incompletedOrders, setIncompletedOrders] = useState([]);

 

  useEffect(() => {
    fetchVendorOrders();
  }, []);


  const fetchVendorOrders = async () => { 
    try{ 
      const vendorOrders = await getVendorOrders(); 
      setCompletedOrders(vendorOrders.completedOrders)  
      setIncompletedOrders(vendorOrders.incompletedOrders)
    }catch(error){ 
      console.error('Failed to fetch menu items:', error);
    }
    
  }  

  const handleMarkComplete = async (orderId) => {
    try { 

      console.log("updating the order id:",orderId)
      //await markOrderComplete(orderId);
      //fetchVendorOrders(); // Refresh the orders
    } catch (error) {
      console.error('Failed to mark order as complete:', error);
    }
  };

  const renderOrderTable = (orders, isCompleted) => (
    <table className="orders-table">
      <thead>
        <tr>
          <th>Status</th>
          <th>User Name</th>
          <th>Items</th>
          <th>Quantity</th>
          <th>{isCompleted ? 'Completion Time' : 'Order Time'}</th>
          {!isCompleted && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {orders.map((order, index) => (
          <tr key={index}>
            <td>
              <span 
                className={`status-indicator ${isCompleted ? 'status-complete' : 'status-incomplete'}`}
              ></span>
              {isCompleted ? 'Completed' : 'Pending'}
            </td>
            <td>{order.usersName}</td>
            <td>{order.foodItem.join(', ')}</td>
            <td>{order.foodQuantity.join(', ')}</td>
            <td>{isCompleted ? order.completionTime : order.orderTime}</td>
            {!isCompleted && (
              <td>
                <button 
                  className="mark-complete-btn"
                  onClick={() => handleMarkComplete(order.id)}
                >
                  Mark Complete
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <Vendornavbar />
      <div className="orders-container">
        <h1>Orders</h1>
        <br></br>
        <h2>Incomplete Orders</h2>
        {renderOrderTable(incompletedOrders, false)}
        <h2>Completed Orders</h2>
        {renderOrderTable(completedOrders, true)}
      </div>
    </div>
  );
}
