import { useId } from 'react';
import config from '../config/config'
import { doc, getDoc, getDocs, collection, arrayUnion, writeBatch, updateDoc, setDoc } from "firebase/firestore"; 
const { fdb, auth, storage } = require('../config/firebaseSetup');

export const fetchMenuItems = async () => {
    const url = `${config.apiBaseUrl}/menuAPI/items`
    console.log("Does this even get hit");
    var test = auth.currentUser.uid;
    console.log(test);
    var token = await auth.currentUser.getIdToken();
    console.log("We got the following token" + token);
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
    
    if (response.ok) {
        console.log("Response was fine")
        const res = await response.json();
        return res;
    }
};

export const addMenuItems = async (items) => {
    if (items.length === 0) {
        alert("There must be items to add.");
        return;
    }
    const url = `${config.apiBaseUrl}/menuAPI/add`
    console.log("Is our create menu endpoint correct " + url)
    var token = await auth.currentUser.getIdToken();
    console.log("We got this id token for menu add")
    console.log(token)
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ items })
    });
    console.log("Did the call finish")
    if (response.ok) {
        console.log("menu add response was ok")
        return true;
    } else {
        return false;
    }
};

export const saveUpdatedItem = async (updatedItem) => {
    const url = `${config.apiBaseUrl}/menuAPI/update`
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ updatedItem })
    });
    console.log("Did the call finish")
    if (response.ok) {
        console.log("item update response was ok")
        const res = await response.json();
        return res;
    } else {
        return false;
    }
};

export const deleteMenuItem = async (item) => {
    const url = `${config.apiBaseUrl}/menuAPI/delete`
    var token = await auth.currentUser.getIdToken();
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ item })
    });
    console.log("Did the call finish")
    if (response.ok) {
        console.log("item delete response was ok")
        const res = await response.json();
        return res;
    } else {
        return false;
    }
};