import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider } from 'react-router-dom';  
import { Home } from './components/Home'; 
import { Login } from './components/Login'; 
import { Register } from './components/Register'; 
import {VerifyEmail} from './components/VerifyEmail';
import { VendorRegistration } from './components/VendorRegistration'; 
import { AdminPortal } from './components/AdminPortal';
import { Vendorportal } from './components/vendorportal/Vendorportal';
import { Dashboard } from './components/vendorportal/Dashboard';
import {Vendornavbar} from './components/Vendornav';
import {Flashsales} from './components/vendorportal/Flashsales';
import {CreateMenuItem} from './components/vendorportal/CreateMenuItem';
import {Menu} from './components/vendorportal/Menu';
import {PasswordChange} from "./components/PasswordChange";
import { AboutUs } from './components/About';
import { ContactUs } from './components/Contact'; 
import { Orders } from './components/vendorportal/Orders';
import Refresh from './components/Refresh';
import Return from './components/Return';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  }, 
  {
    path: "/about",
    element: <AboutUs/>,
  },
  {
    path: "/PasswordChange",
    element: <PasswordChange/>,
  },

  {
    path: "/contact",
    element: <ContactUs/>,
  }, 
  {
    path: "/login",
    element: <Login/>,
  }, 
  {
    path: "/register",
    element: <Register/>,
  }, 
  {
    path: "/verify-email",
    element: <VerifyEmail/>,
  },
  {
    path: "/vendor-reg",
    element: <VendorRegistration />
  }, 
  {
    path: "/admin-portal",
    element: <AdminPortal/>,
  },
  
  {
    path: "/vendorportal",
    element: <Vendorportal/>,
  },

  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
    path: "/Vendornavbar",
    element: <Vendornavbar/>,
  },
  {
    path: "/About",
    element: <AboutUs/>
  },
  {
    path: "/Flashsales",
    element: <Flashsales />
  },
  {
    path: "/new-menu-item",
    element: <CreateMenuItem/>
  },
  {
    path: "/menu",
    element: <Menu/>
  },
  {
    path: "/refresh/:connectedAccountId",
    element: <Refresh />,
  },
  {
    path: "/return/:connectedAccountId",
    element: <Return />,
  }, 
  {
    path: "/orders",
    element: <Orders />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);