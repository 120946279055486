import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Vendornavbar } from "./Vendornav";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, fdb } from "../config/firebaseSetup";
import './Return.css';

// Initialize with a function that returns a promise
export const getInitialOnboarding = async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      const vendorsRef = doc(fdb, "Vendors", user.uid);
      const inVendorsRef = await getDoc(vendorsRef);
      if (inVendorsRef.exists()) {
        const data = inVendorsRef.data();
        return data.initialOnboarding || false;
      }
    } catch (e) {
      console.error("Error fetching initial onboarding status:", e);
    }
  }
  return false;
};

export default function Return() {
  const { connectedAccountId } = useParams();
  const [onboardingState, setOnboardingState] = useState(false);

  useEffect(() => {
    const checkAndUpdateInitialOnboarding = async () => {
      const initialStatus = await getInitialOnboarding();
      setOnboardingState(initialStatus);

      if (!initialStatus) {
        const user = auth.currentUser;
        if (user) {
          try {
            const vendorsRef = doc(fdb, "Vendors", user.uid);
            await updateDoc(vendorsRef, {
              initialOnboarding: true,
            });
            setOnboardingState(true);
            console.log("Initial onboarding updated successfully");
          } catch (e) {
            console.error("Error updating initial onboarding:", e);
          }
        }
      } else {
        console.log("Initial onboarding already completed");
      }
    };

    checkAndUpdateInitialOnboarding();
  }, []);

  console.log("Current onboardingState:", onboardingState);
  console.log("Connected Account ID:", connectedAccountId);

  return (
    <div className="return-container">
      <Vendornavbar />
      <div className="return-content">
        <h2>Details submitted</h2>
        <p>That's everything we need for now.</p>
        <p>Initial onboarding status: {onboardingState ? "Completed" : "Not completed"}</p>
      </div>
    </div>
  );
}