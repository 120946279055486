import { useState } from 'react'
import { auth } from '../config/firebaseSetup'
import './Register.css'
import { useNavigate, Link } from 'react-router-dom'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { Navbar } from './Navbar'

import React from 'react'

export const Register = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const validatePassword = () => {
    //Check if both password and confirm password are the same(Make sure they are not empty as well)
    let isValid = true
    if (password !== '' || confirmPassword !== '') {
      if (password !== confirmPassword) {
        isValid = false
        setError('Passwords do not match')
      }
    }
    return isValid
  }

  const register = e => {
    e.preventDefault()
    if (validatePassword()) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              navigate('/verify-email') //Change this back to 'verify-email'
            }).catch((err) => alert(err.message))
        })
        .catch((err) => setError(err.message))
    }
  }


  return (
    <div>
      <Navbar />
      <div className='center'>
        <div className='auth'>
          <h1 className='font-color'>Register</h1>
          {error && <div className='auth__error'>{error}</div>}
          <form onSubmit={register} name='registration_form'>
            <input
              type='email'
              value={email}
              placeholder="Enter your email"
              required
              onChange={e => setEmail(e.target.value)} />

            <input
              type='password'
              value={password}
              required
              placeholder='Enter your password'
              onChange={e => setPassword(e.target.value)} />

            <input
              type='password'
              value={confirmPassword}
              required
              placeholder='Confirm password'
              onChange={e => setConfirmPassword(e.target.value)} />

            <button className='register-button' type='submit'>Register</button>
          </form>
          <span>
            <p className='register-account'>
              Already have an account?&nbsp;

              <Link to='/login'>Login</Link>
            </p>
          </span>
        </div>
      </div>
    </div>
  )
}
