import React, { useState, useEffect, useRef } from 'react';
import { fdb, auth } from '../../config/firebaseSetup';
import { Vendornavbar } from '../Vendornav';
import { getDocs, collection, addDoc, updateDoc, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Flashsales.css';
import { fetchMenuItems } from '../../API/menuAPI';
import { fetchVendorFlashSales, createFlashSale, deletePastSale, fetchSaleById, updateFlashSale, deleteFlashSale, addToPast, fetchPastSales } from '../../API/flashsaleAPI';

async function copyVendorsToVendorDev() {
  try {
    const vendorsCollectionRef = collection(fdb, 'Vendors');
    const querySnapshot = await getDocs(vendorsCollectionRef);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const vendorDevCollectionRef = collection(fdb, 'VendorDev');
      setDoc(doc.ref, data, { merge: true });
    });

    console.log('Documents copied successfully from Vendors to VendorDev collection');
  } catch (error) {
    console.error('Error copying documents:', error);
  }
}

export const Flashsales = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [discount, setDiscount] = useState('');
  const [itemAmount, setItemAmount] = useState('');
  const [timeAmount, setTimeAmount] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [menuItems, setMenuItems] = useState({
    Breakfast: [],
    Lunch: [],
    Dinner: [],
    Drinks: [],
    Dessert: []
  });
  const [userMenuItems, setUserMenuItems] = useState({});
  const [flashSales, setFlashSales] = useState([]);
  const [pastSales, setPastSales] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentSaleId, setCurrentSaleId] = useState(null);
  const [reactivatingSale, setReactivatingSale] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchsetMenuItems();
        fetchsetFlashSales();
        fetchsetPastSales();
      } else {
        navigate('/login');
      }
    });

    const interval = setInterval(updateFlashSales, 1000);

    return () => {
      unsubscribe();
      clearInterval(interval);
    };
  }, []);

  const fetchsetMenuItems = async () => {
    const fetchedMenuItems = await fetchMenuItems();
    if (fetchedMenuItems) {
      setUserMenuItems(fetchedMenuItems);
    } else {
      alert('Error fetching all flash sales')
    }
  };

  const fetchsetFlashSales = async () => {
    try {
      const fetchedFlashSales = await fetchVendorFlashSales()
      console.log("This is what we loaded")
      console.log(fetchedFlashSales)
      setFlashSales(fetchedFlashSales);
    } catch (error) {
      console.log(error)
    }
  }

  const fetchsetPastSales = async () => {
    try {
      console.log("Do we fetch past sales")
      const fetchedPastSales = await fetchPastSales();
      console.log(fetchedPastSales)
      setPastSales(fetchedPastSales);
    } catch (error) {
      console.log(error)
    }
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedProduct('');
    setDiscountedPrice('');
  };

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
    calculateDiscountedPrice(event.target.value, discount);
  };

  const handleDiscountChange = (event) => {
    const value = event.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
      setDiscount(value);
      calculateDiscountedPrice(selectedProduct, value);
    }
  };

  const handleAmountOfItemsChange = (event) => {
    const value = event.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
      setItemAmount(value);
    }
  };

  const handleTimeAmountChange = (event) => {
    setTimeAmount(event.target.value);
  };

  const calculateDiscountedPrice = (productName, discountValue) => {
    if (productName && discountValue !== '' && Number(discountValue) >= 0 && Number(discountValue) <= 100) {
      const product = userMenuItems[selectedCategory]?.find(item => item.name === productName);
      if (product) {
        const originalPrice = product.price;
        const discountAmount = (originalPrice * discountValue) / 100;
        const finalPrice = originalPrice - discountAmount;
        setDiscountedPrice(finalPrice.toFixed(2));
      }
    } else {
      setDiscountedPrice('');
    }
  };

  const clearForm = () => {
    setSelectedCategory('');
    setSelectedProduct('');
    setDiscount('');
    setItemAmount('');
    setTimeAmount('');
    setDiscountedPrice('');
    setReactivatingSale(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("we hit submit")
    if (!selectedCategory || !selectedProduct) {
      setErrorMessage('Please select a category and a product.');
      return;
    }
    if (discount === '' || Number(discount) < 0 || Number(discount) > 100) {
      setErrorMessage('Please enter a valid discount between 0 and 100.');
      return;
    }
    if (timeAmount === '') {
      setErrorMessage('Please enter a valid time for the sale.');
      return;
    }
    const [inputHours, inputMinutes] = timeAmount.split(':').map(Number);
    const now = new Date();
    const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), inputHours, inputMinutes);
    if (endTime < now) {
      endTime.setDate(endTime.getDate() + 1);
    }
    const totalMinutes = Math.floor((endTime - now) / 60000);

    const startTime = new Date();
    const saleData = {
      category: selectedCategory,
      productName: selectedProduct,
      discountedPrice: discountedPrice,
      time: totalMinutes,
      startTime: startTime.toISOString(),
    };

    const user = auth.currentUser;
    if (user) {
      if (isEditMode && currentSaleId) {
        const check = await fetchSaleById(currentSaleId)
        if (check) {
          try {
            await updateFlashSale(saleData, currentSaleId)
            setFlashSales(currentSales => currentSales.map(sale => sale.id === currentSaleId ? { ...saleData, id: currentSaleId } : sale));
            setErrorMessage('');
            if (reactivatingSale) {
              await deletePastSale(currentSaleId);
              setPastSales(currentSales => currentSales.filter(sale => sale.id !== currentSaleId));
              setReactivatingSale(null);
            }
          } catch (error) {
            setErrorMessage(`Error updating flash sale: ${error.message}`);
          }
        } else {
          try {
            const docRefId = await createFlashSale(saleData);
            setFlashSales(currentSales => [...currentSales, { ...saleData, id: docRefId }]);
            setErrorMessage('');
            if (reactivatingSale) {
              await deletePastSale(currentSaleId);
              setPastSales(currentSales => currentSales.filter(sale => sale.id !== currentSaleId));
              setReactivatingSale(null);
            }
          } catch (error) {
            setErrorMessage(`Error adding flash sale: ${error.message}`);
          }
        }
      } else {
        console.log("Did we get to the create point")
        try {
          const docRefId = await createFlashSale(saleData);
          setFlashSales(currentSales => [...currentSales, { ...saleData, id: docRefId }]);
          setErrorMessage('');
          if (reactivatingSale) {
            console.log("We are reactivating a sale")
            await deletePastSale(currentSaleId);
            setPastSales(currentSales => currentSales.filter(sale => sale.id !== currentSaleId));
            setReactivatingSale(null);
          }
        } catch (error) {
          setErrorMessage(`Error adding flash sale: ${error.message}`);
        }
      }
      clearForm()
      setIsEditMode(false);
      setCurrentSaleId(null);
    } else {
      setErrorMessage('User is not authenticated');
    }
  }

  const editSale = (sale) => {
    setSelectedCategory(sale.category);
    setSelectedProduct(sale.productName);
    setDiscount((sale.discountedPrice / sale.originalPrice) * 100);
    setItemAmount(sale.itemAmount);
    setTimeAmount(`${Math.floor(sale.time / 60).toString().padStart(2, '0')}:${(sale.time % 60).toString().padStart(2, '0')}`);
    setDiscountedPrice(sale.discountedPrice);
    setIsEditMode(true);
    setCurrentSaleId(sale.id);
    setModalIsOpen(true);
  };

  const deleteSale = async (sale) => {
    const user = auth.currentUser;
    if (user) {
      console.log("WE ARE TRYING TO DELETE")
      console.log(sale.id)
      try {
        await deleteFlashSale(sale.id)
        setFlashSales(currentSales => currentSales.filter(currSale => currSale.id !== sale.id));
      } catch (error) {
        console.error("Error deleting document: ", error)
      }
    } 
  };

  const updateFlashSales = async () => {
    console.log("We are trying to update a flash sale")
    const now = new Date();
    const user = auth.currentUser;
    if (user) {
      setFlashSales(currentSales => {
        const updatedFlashSales = []

        for (const sale of currentSales) {
          const endTime = new Date(new Date(sale.startTime).getTime() + sale.time * 60000);
          const remainingTime = Math.floor((endTime - now) / 1000);

          if (remainingTime <= 0) {
            console.log("OUT OF TIME")
            const saleData = { ...sale, endTime: endTime.toISOString() };
            (async () => {
              try {
                await addToPast(saleData, sale.id)
              } catch (error) {
                console.error("Error moving expired sale to PastSales: ", error);
              }
            })();
          } else {
            updatedFlashSales.push(sale);
          }
        }
        return updatedFlashSales
      });
      fetchsetPastSales(user.uid)
    }
  }

  const reactivateSale = (sale) => {
    setSelectedCategory(sale.category);
    setSelectedProduct(sale.productName);
    setDiscount((sale.discountedPrice / sale.originalPrice) * 100);
    setItemAmount(sale.itemAmount);
    setDiscountedPrice(sale.discountedPrice);
    setIsEditMode(true);
    setCurrentSaleId(sale.id);
    setReactivatingSale(sale);
    setTimeAmount('');
    setModalIsOpen(true);
  };

  return (
    <div className='main-container'>
      <Vendornavbar />
      <div className={`modal ${modalIsOpen ? 'is-open' : ''}`}>
        <div className='modal-container'>
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <label>Meal Category</label>
              <select value={selectedCategory} onChange={handleCategoryChange} required>
                <option value="">Select a category</option>
                {Object.keys(menuItems).map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </select>

              <label>Product Name</label>
              <select value={selectedProduct} onChange={handleProductChange} required disabled={!selectedCategory}>
                <option value="">Select a product</option>
                {selectedCategory && userMenuItems[selectedCategory] && userMenuItems[selectedCategory].map((product, index) => (
                  <option key={index} value={product.name}>{product.name}</option>
                ))}
              </select>

              <label>Discount</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  placeholder="Enter discount %"
                  value={discount}
                  onChange={handleDiscountChange}
                  required
                />
                <span className="percentage-symbol">%</span>
              </div>

              <label>Amount of items</label>
              <input
                type="number"
                placeholder='Enter amount of items for the deal'
                value={itemAmount}
                onChange={handleAmountOfItemsChange}
                required
              />

              <label>Time estimate</label>
              <input
                type="time"
                placeholder='Enter how long for the deal to last'
                value={timeAmount}
                onChange={handleTimeAmountChange}
                required
              />

              {discountedPrice && (
                <div className="discounted-price">
                  <label>Discounted Price:</label>
                  <input type="text" value={`$${discountedPrice}`} readOnly />
                </div>
              )}

              {errorMessage && <p className="error-message">{errorMessage}</p>}

              <button className="gradient-color" type="submit">{isEditMode ? 'Update' : 'Submit'}</button>
            </form>
          </div>
        </div>
      </div>

      <h2 className="flash-sales-title">All Active Sales</h2>
      <div className="flash-sales-container">
        {flashSales.map((sale, index) => {
          const endTime = new Date(new Date(sale.startTime).getTime() + sale.time * 60000);
          const remainingTime = Math.max(0, Math.floor((endTime - new Date()) / 1000));
          const hours = Math.floor(remainingTime / 3600);
          const minutes = Math.floor((remainingTime % 3600) / 60);
          const seconds = remainingTime % 60;
          return (
            <div key={index} className="flash-sale-card">
              <button className="delete-button-top" onClick={() => deleteSale(sale)}>×</button>
              <p><strong>Category:</strong> {sale.category}</p>
              <p><strong>Product:</strong> {sale.productName}</p>
              <p><strong>Discounted Price:</strong> ${sale.discountedPrice}</p>
              <p><strong>Time Remaining:</strong> {hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
              <div className="card-buttons">
                <button className="gradient-color" onClick={() => editSale(sale)}>Edit</button>
              </div>
            </div>
          );
        })}
      </div>

      <h2 className="past-sales-title">Past Flash Sales</h2>
      <div className="flash-sales-container">
        {pastSales.map((sale, index) => (
          <div key={index} className="flash-sale-card">
            <p><strong>Category:</strong> {sale.category}</p>
            <p><strong>Product:</strong> {sale.productName}</p>
            <p><strong>Discounted Price:</strong> ${sale.discountedPrice}</p>
            <p><strong>Ended At:</strong> {new Date(sale.endTime).toLocaleString()}</p>
            <div className="card-buttons">
              <button className="gradient-color" onClick={() => reactivateSale(sale)}>Reactivate</button>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};