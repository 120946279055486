import './App.css';
import { Navbar } from './components/Navbar';
import { auth } from './config/firebaseSetup';
import { Home } from './components/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Login } from './components/Login';
import { signOut } from 'firebase/auth';
import { Register } from './components/Register';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { VendorRegistration } from './components/VendorRegistration'; 
import { AdminPortal } from './components/AdminPortal'; 
import { Dashboard } from './components/vendorportal/Dashboard';
import { Vendorportal } from './components/vendorportal/Vendorportal';
import {Vendornavbar} from './components/Vendornav';
import {Menu} from './components/vendorportal/Menu';
import {CreateMenuItem} from "./components/vendorportal/CreateMenuItem";
import { PasswordChange } from './components/PasswordChange';
import { AboutUs } from './components/About';
import { ContactUs } from './components/Contact';
import {Flashsales} from './components/vendorportal/Flashsales'; 
import {Orders} from './components/vendorportal/Orders';
import Refresh from './components/Refresh';
import Return from './components/Return';


function checkAndLogout() {
  if (!sessionStorage.getItem('appLoaded')) {
    signOut(auth);
  }
  sessionStorage.setItem('appLoaded', 'true');
}

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    checkAndLogout();
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  });

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path='/about' element={
          <AboutUs />
        } />

        <Route exact path='/contact' element={
          <ContactUs />
        } />
        <Route path='/' element={<Home />} />
        <Route path='/new-menu-item' element={<CreateMenuItem />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/vendor-reg' element={<VendorRegistration />} />
        <Route path="/login" element={
          currentUser && !currentUser.emailVerified
            ? <Login />
            : <Navigate to='/vendorportal' replace />
        } />
        <Route path="/register" element={
          currentUser && !currentUser.emailVerified
            ? <Register />
            : <Navigate to='/' replace />
        } />
        <Route path='/admin-portal' element={<AdminPortal />} />
        <Route path='/Vendorportal' element={<Vendorportal />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/About' element={<AboutUs />} />
        <Route path='/Flashsales' element={<Flashsales />} />
        <Route path='/Vendornavbar' element={<Vendornavbar />} />
        <Route path= '/new-menu-item' element={< CreateMenuItem />} /> 
        <Route path='/orders' element={<Orders/>}/>
        <Route path = '/PasswordChange' element={< PasswordChange/>} />
        <Route path = '/Refresh' element={< Refresh/>} />
        <Route path = '/Return' element={< Return/>} />
        <Route path="*" element={<div>Not Found</div>} /> {/* Fallback Route */}
      </Routes>
    </Router>
  );
}

export default App;